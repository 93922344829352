
::v-deep {
    .v-item-group {
        display: flex;
        width: 100%;
        .v-btn,
        .v-tab {
            width: 100%;
            min-width: auto !important;
            height: 50px !important;
            font-size: 14px !important;
            margin: 0;
            border-top: 1px solid var(--border-color) !important;
            &--active {
                color: var(--v-grey-darken4) !important;
            }
        }
    }
}
